.project__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.buttonP {
  display: inline-block;
  background-color: var(--title-color);
  color: white;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}

.buttonP:hover {
  background-color: var(--title-color-dark);
}

.project__item {
  color: var(--title-color);
  padding: 0.25rem 0.75rem;

  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.project__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
}

.project__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.project__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
  box-shadow: 0 0 0 1px rgb(190, 190, 190), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.project__img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

.project__title {
  font-size: larger;
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
  font-weight: bolder;

}

.project__button {
  color: white;
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  padding: 0.5rem 1rem;
}

.project__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.project__button:hover .project__button-icon {
  transform: translateX(0.25rem);
}

/* ACTIVE PROJECT */
.active__project {
  background-color: var(--title-color);
  color: var(--container-color);
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .project__container {
    gap: 1.25rem;
  }

  .project__card {
    padding: 1rem;
  }

  .project__img {
    margin-bottom: 0.75rem;
  }

  .project__title {
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 768px) {
  .project__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .project__container {
    grid-template-columns: 1fr;
  }

  .project__img {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .project__item {
    font-size: var(--small-font-size);
  }

  .project__filters {
    column-gap: 0.25rem;
  }
}
